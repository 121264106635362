import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Popper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ThemeContext from '../../contexts/theme/theme-context';

const BigImage = ({ translate, id, open, clickInfo, setClickInfo }) => {

    const { darkTheme, colors } = useContext(ThemeContext);

    const classes = makeStyles({
        img: {
            height: 'clamp(150px, 400px, 100vh)',
            width: 'clamp(200px, 600px, 100vw)',
            cursor: 'pointer',
            border: `3px ridge ${darkTheme ? colors.gold : 'Silver'}`
        },
    })();


    const resetPopper = () => {
        setClickInfo({ open: false, anchor: null, url: '' });
    };


    return (
        <Popper className="largeImage" id={id} open={open} anchorEl={clickInfo.anchor}>
            <img className={classes.img}
                src={clickInfo?.url}
                onClick={resetPopper}
                title={translate("Close")}
                alt="img">
            </img>
            <IconButton className='largeImage'
                onClick={resetPopper}
                sx={{ color: darkTheme ? colors.gold : 'Silver' }}
                title={translate("Close")}>
                <CancelPresentationIcon />
            </IconButton>
        </Popper>
    );
};

export default BigImage;
