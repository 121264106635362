import React, { useState, useContext, useEffect } from "react";
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Zoom from "@mui/material/Zoom";
import Alert from '@mui/material/Alert';

import { StartupContext } from "../../contexts/startup-context";
import ThemeContext from "../../contexts/theme/theme-context";


const TopBanner = () => {
    const { langTranslations, translate } = useContext(StartupContext);
    const { colors } = useContext(ThemeContext);
    const [banner, setBanner] = useState();
    const [banners, setBanners] = useState([]);

    const filterBanners = () => Object.keys(langTranslations || []).filter(x => x.indexOf("Banner") === 0);



    useEffect(() => {
        let timeout;
        
        if (!banners.length && Object.keys(langTranslations || {}).length) {
            setBanners(filterBanners());
        }

        if (!banner && banners && banners.length) {
            setBanner(banners[0]);
        };

        if (banners && banners.length) {
            let nextBannerIndex = banners.indexOf(banner) + 1;
            nextBannerIndex = nextBannerIndex === banners.length ? 0 : nextBannerIndex;
            timeout = setTimeout(() => setBanner(banners[nextBannerIndex]), 10000);
        }

        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [langTranslations, banners, banner]);


    const BannerBox = styled(Box)(({ theme }) => ({
        height: '66px',
        paddingTop: '16px'
    }));

    const AlertColored = styled(Alert)(({ theme }) => ({
        backgroundColor: 'transparent',
        color: theme.palette.mode === "dark" ? colors.gold : theme.palette.primary.dark,
        fontSize: '1.1rem',
        fontWeight: 'bold',
        justifyContent: 'center',
    }));

    return (
        <BannerBox>
            <Zoom in={!!banner} mountOnEnter unmountOnExit timeout={2000} >
                <AlertColored icon={false} variant="filled">{translate(banner)}</AlertColored>
            </Zoom>
        </BannerBox>
    );
};

export default TopBanner;

