
import { TOGGLE_THEME, StorageKeys } from "../../constants";

const ThemeReducer = (state, action) => {
    switch (action.type) {
        case TOGGLE_THEME: {
            localStorage.setItem(StorageKeys.IsDarkTheme, !state.darkTheme);
            document.getElementById("root").className = !state.darkTheme ? 'dark' : 'light';
            return {
                ...state,
                darkTheme: !state.darkTheme,
            };
        }
        default:
            return state;
    }
};

export default ThemeReducer;