import React, { useContext, useState, useEffect } from "react";
import AlertItem from "./alert-item";
import AlertContext from "../../contexts/alert/alert-context";

const AlertComponent = () => {
    const { alert, removeAlert } = useContext(AlertContext);
    const [open, setOpen] = useState(false);
    const [snackPack, setSnackPack] = useState([]);
    const [alertData, setAlertData] = useState(alert);

    useEffect(() => {

        if (snackPack.length > 2) {
            setSnackPack(snackPack.slice(-2));
        }

        if (alert) {
            setSnackPack([...snackPack, alert]);
            removeAlert();
        }

        if (snackPack.length > 0 && !alertData) {
            setAlertData(snackPack.shift());
            setOpen(true);
        }
        // eslint-disable-next-line
    }, [alert, alertData, open]);


    const handleClose = (_, reason) => {
        if (reason === "clickaway") return;
        setAlertData(null);
    };

    return (<AlertItem alert={alertData} open={open} handleClose={handleClose} />);
};

export default AlertComponent;
