import { StorageKeys } from "../../constants";
import useTranslationHelpers from "./use-translation-helpers";

const useStoredTranslations = () => {

    const translationsOnStorage = localStorage.getItem(StorageKeys.Translations)
    const storedTranslations = !!translationsOnStorage ? JSON.parse(translationsOnStorage) : null;
    const storedLanguage = localStorage.getItem(StorageKeys.Language);

    const { createLangTranslations } = useTranslationHelpers();

    const getStoredTranslations = () => {
        if (storedLanguage && Object.keys(storedTranslations || {}).length) {
            const { culture, languageKeys, parsedTranslations } = createLangTranslations(storedLanguage, storedTranslations);
            //console.log(`GETSTOREDTRANSLATIONS -> translation created: ${success} -> from storage for ${storedLanguage}/${culture}, parsedTranslations: ${Object.keys(parsedTranslations || {}).length},`);

            return {
                culture,
                storedLanguageKeys: languageKeys,
                storedLangTranslations: parsedTranslations,
            }
        };

        return {
            culture: storedLanguage,
            storedLanguageKeys: null,
            storedLangTranslations: null
        };
    };

    return ({ getStoredTranslations });
};

export default useStoredTranslations;