import React, { useContext } from "react";
import { StartupContext } from "../contexts/startup-context";


const withComponent = (Component) => {

    return (props) => {


        const {
            apiVersion,
            language,
            languageKeys,
            langTranslations,
            startupLoaded,
            isClientLocal,
            isAuthorizedAdminIp,
            translate,
            changeLanguage } = useContext(StartupContext);

        //console.log(`with-component (${props.location.pathname}) -> startupLoaded:${startupLoaded}, apiVersion:${apiVersion}, language:${language}, languageKeys:[${languageKeys}], langTranslations:${langTranslations && Object.keys(langTranslations).length}, isClientLocal: ${isClientLocal}`)

        return (
            <Component
                apiVersion={apiVersion}
                language={language}
                languageKeys={languageKeys}
                langTranslations={langTranslations}
                translate={translate}
                changeLanguage={changeLanguage}
                startupLoaded={startupLoaded}
                isClientLocal={isClientLocal}
                isAuthorizedAdminIp={isAuthorizedAdminIp}
                {...props}>

            </Component>);

    };
};

export default withComponent;