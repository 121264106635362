import React, { useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ThemeContext from "../../contexts/theme/theme-context";

import { StartupContext } from "../../contexts/startup-context";

const LanguagePicker = () => {

    const { language, languageKeys, translate, changeLanguage } = useContext(StartupContext);
    const { colors } = useContext(ThemeContext);

    const handleChange = (ev) => {
        if (language !== ev.target.value) {
            changeLanguage(ev.target.value);
        }
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel sx={{ color: colors.gold }}> {translate("Language")} </InputLabel>
                <Select label={translate(language)} value={languageKeys && languageKeys.length ? language : ''} onChange={handleChange}
                    sx={{ height: '40px', marginRight: '20px', color: colors.gold }}>
                    {(languageKeys || []).map(key => <MenuItem key={key} value={key}>{translate(key)}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    );
};

export default LanguagePicker;
