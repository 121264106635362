import React, { useContext } from 'react';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import ThemeContext from '../../contexts/theme/theme-context';
import { StorageKeys } from '../../constants';


const ThemeSwitcher = () => {
    const { darkTheme, toggleTheme } = useContext(ThemeContext);

    const handleChange = (event) => {
        localStorage.setItem(StorageKeys.IsDarkTheme, `${event.target.checked}`);
        toggleTheme();
    };


    return (
        <Typography sx={{ marginLeft: '30px', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
            <WbSunnyIcon sx={{ color: '#ff8c00' }} />
            <Switch
                checked={!!darkTheme}
                onChange={handleChange}
                color="primary"
                name="themeSwitch"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            /><NightsStayIcon sx={{ color: '#1e90ff' }} />
        </Typography>
    );
};

export default React.memo(ThemeSwitcher);
