import React, { useContext, useState, useRef } from 'react';
import { Box } from '@mui/system';
import ReactPlayer from "react-player/facebook"
import styled from '@mui/material/styles/styled';
import usePageTitle from '../common/use-page-title';
import CircularProgress from '@mui/material/CircularProgress';

import withComponent from '../common/with-component';
import ThemeContext from '../contexts/theme/theme-context';
import HomeContainer from './home/home-container';


const VideoFacebook = ({ translate }) => {

  usePageTitle(translate("Home"));
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const { colors } = useContext(ThemeContext);
  const player = useRef(null)

  const VideoBox = styled(Box)(({ theme }) => ({
    background: `repeating-linear-gradient(-45deg, 
      ${colors.gold}, 
      ${colors.gold} 5px, 
      transparent 5px, 
      transparent 10px)`,
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'content',
    padding: '10px',
    position: 'relative',
  }));

  const SlyledPlayer = styled(ReactPlayer)(({ theme }) => ({
    backgroundColor: theme.palette.background.default
  }));

  const onPlayerBufferEnd = (reactPlayer) => {
    setPlayerLoaded(true);
  }

  return (
    <HomeContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', }}>
        <VideoBox>
          {playerLoaded ? null : <CircularProgress sx={{ position: 'absolute', zIndex: '0', bottom: '47%', left: '44%' }} />}
          <SlyledPlayer ref={player} url="https://fb.watch/9ffKVaixq3/"
            controls
            playing={playerLoaded}
            loop
            playsinline
            width={300}
            height={522}
            onBufferEnd={onPlayerBufferEnd}
          ></SlyledPlayer>
        </VideoBox>
      </Box>
    </HomeContainer>
  );
};

export default withComponent(VideoFacebook);
