import React, { Fragment } from 'react';
import styled from '@mui/material/styles/styled';
import usePageTitle from '../common/use-page-title';
import withComponent from '../common/with-component';
import FacebookIcon from '@mui/icons-material/Facebook';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RoomIcon from '@mui/icons-material/Room';


const Contacts = ({ translate }) => {

  usePageTitle(translate("Contacts"));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:hover': { textDecoration: "underline" }
  }));


  return (
    <Fragment>
      <Box>
        <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '30px' }}>{translate('Contacts')}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <List>
          <ListItem title={translate("Phone")}>
            <ListItemIcon>
              <LocalPhoneIcon />
            </ListItemIcon>
            <ListItemText>+370 675 43396</ListItemText>
          </ListItem>
          <StyledListItem sx={{ cursor: 'pointer' }} title={translate("Email")}
            onClick={() => window.open('mailto:proximoline@gmail.com?subject=ABC-TERMO')}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText>proximoline@gmail.com</ListItemText>
          </StyledListItem>
          <StyledListItem sx={{ cursor: 'pointer' }} title={translate("Address")}
            onClick={() => window.open('https://www.google.lt/maps/place/Ažušilės+g.+9,+Ignalina+30125/@55.3433565,26.1728655,17z', '_blank')}>
            <ListItemIcon>
              <RoomIcon />
            </ListItemIcon>
            <ListItemText>Ažušilės g. 9, LT-30125 Ignalina, {translate("Lithuania")}</ListItemText>
          </StyledListItem>
          <StyledListItem sx={{ cursor: 'pointer' }} title={translate("Requisites")}
            onClick={() => window.open('https://rekvizitai.vz.lt/imone/abc_termo/', '_blank')}>
            <ListItemIcon>
              <img src={`${process.env.PUBLIC_URL}/img/rekvizitai.ico`} alt="facebook" height={20} />
            </ListItemIcon>
            <ListItemText>{translate("Requisites")}</ListItemText>
          </StyledListItem>
          <StyledListItem sx={{ cursor: 'pointer' }} title={translate("Open")}
            onClick={() => window.open('https://www.facebook.com/Abc-Termo-100985485714422/', '_blank')}>
            <ListItemIcon>
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText>Facebook</ListItemText>
          </StyledListItem>
        </List>
      </Box>
    </Fragment>
  );
};

export default withComponent(Contacts);
