const withHome = (clickInfo, setClickInfo) => {

    const extractUrl = (parentElement) => {
        const elementSrc = parentElement.getElementsByTagName("img")[0].src;
        return elementSrc.substring(0, elementSrc.length - 12) + ".gif"
    }

    const handleClick = (event) => {
        const hide = clickInfo.anchor?.className === event.currentTarget.className;
        setClickInfo(hide ? { open: false, anchor: null, url: '' } :
            {
                open: true,
                anchor: event.currentTarget,
                url: extractUrl(event.currentTarget)
            });
    };

    const appendix = (from) => String(from + 1).padStart(2, '0');

    const open = clickInfo.open;
    const id = open ? 'simple-popper' : undefined;


    return (
        {
            id: id,
            appendix: appendix,
            handleClick: handleClick
        }
    );
};

export default withHome;
