export const ThemeColors = (isThemeDark) => {
  return {
    gold: isThemeDark ? '#ff8c00' : '#ff8c00',
    primary: isThemeDark ? "#cccccc" : "#000",
    background: {
      default: isThemeDark ? '#0d1117' : '#efefef',
      paper: isThemeDark ? '#060606' : '#fafafa'
    },
    light: isThemeDark ? '#20C0D7' : '#20C0D7',
    main: isThemeDark ? '#0054a6' : '#0054a6',
    dark: isThemeDark ? '#1e3d71' : '#1e3d71',
    contrastText: isThemeDark ? '#fff' : '#000',
    error: isThemeDark ? '#ff0000' : '#dc143c',
  };
};

