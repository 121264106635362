import { StorageKeys } from "../../constants";

const useTranslationHelpers = () => {

    const storedLanguage = localStorage.getItem(StorageKeys.Language);

    const getLanguage = (lang, languageKeys) => {
        if(!languageKeys || !languageKeys.length){
            return lang;
        }
        
        var culture = languageKeys.indexOf(lang) > -1 ? lang : (languageKeys.indexOf(storedLanguage) > -1 ? storedLanguage : "lt");

        if (culture !== lang) {
            localStorage.setItem(StorageKeys.Language, culture);
        }
        return culture;
    };

    const getTranslations = (culture, source) => {
        let obj = {};
        Object.keys(source).map(x => obj[x] = source[x][culture]);
        return obj;
    }

    const createLangTranslations = (lang, source) => {
        //console.log(`useTranslationHelpers -> createLangTranslations(${lang})...`);

        if (!Object.keys(source || {}).length) {
            //console.log(`useTranslationHelperss -> createLangTranslations(${lang}) -> skipping due empty translations`);
            return { culture: lang || storedLanguage, storedLanguageKeys: null, storedLangTranslations: null };
        }

        const languageKeys = Object.keys(source[Object.keys(source)[0]]);
        const culture = getLanguage(lang, languageKeys);
        const parsedTranslations = getTranslations(culture, source);
        return { culture, languageKeys, parsedTranslations };
    };


    return ({ createLangTranslations });
};

export default useTranslationHelpers;