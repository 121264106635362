import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import CallIcon from '@mui/icons-material/Call';
import { StartupContext } from '../../contexts/startup-context';


const Brand = () => {

    const { translate } = useContext(StartupContext);

    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: '2px solid #ff8c00',
        color: '#ff8c00',
    }));

    return (

        <Badge overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="outlined"
            badgeContent={
                <SmallAvatar title={translate("Contacts")} component={Link} to="/contacts">
                    <CallIcon sx={{ color: '#ff8c00', background: '#000' }} />
                </SmallAvatar>
            }>
            <Avatar component={Link} to="/" alt="ABC TERMO" src="img/ABC-TERMO-48.png" sx={{ width: 48, height: 48 }} variant="circle" title="ABC TERMO"></Avatar>
        </Badge>

    );
};

export default React.memo(Brand);