import React, { useContext } from 'react';
import { Box } from '@mui/system';
import styled from '@mui/material/styles/styled';
import { makeStyles } from '@mui/styles';
import Fade from '@mui/material/Fade';
import ThemeContext from '../../contexts/theme/theme-context';

const MainImage = ({ show, imgNumber }) => {

    const { darkTheme, colors } = useContext(ThemeContext);
    const classes = makeStyles({
        img: {
            height: '150px',
            width: '200px',
            border: `3px ridge ${darkTheme ? colors.gold : 'Silver'}`,
        },
    })();


    const StripedContainer = styled(Box)(({ theme }) => ({
        background: `repeating-linear-gradient(-45deg, 
      ${theme.palette.mode === "dark" ? colors.gold : theme.palette.primary.dark}, 
      ${theme.palette.mode === "dark" ? colors.gold : theme.palette.primary.dark} 3px, 
      transparent 3px, 
      transparent 6px)`,
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'content',
        padding: '5px',
        height: '156px',
        marginTop: '185px',
        color: theme.palette.mode === "dark" ? colors.gold : theme.palette.primary.main,
    }));


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Fade in={show} timeout={2000}>
                <StripedContainer key="stripedContainer">
                    <img className={classes.img} src={`img/banner-group-01/banner-${imgNumber}-200x150.gif`} alt="balloon" ></img>
                </StripedContainer>
            </Fade>
        </Box>
    );
};

export default MainImage;
