import React, { createContext, useState, useEffect } from "react";
import useResourceInit from "../common/translations/use-resource-init";

export const StartupContext = createContext();

const StartupProvider = ({ children }) => {

    const [startupLoaded, setStartupLoaded] = useState(false);

    const {
        apiVersion,
        language,
        languageKeys,
        langTranslations,
        requestInfo,
        translate,
        onLanguageChange } = useResourceInit();

    //console.log(`${StartupProvider.name} -> startupLoaded:${startupLoaded}, loading language:${language}, api: ${apiVersion}, langTranslations keys:${langTranslations && Object.keys(langTranslations).length}`);

    const changeLanguage = (lang) => {
        if (lang && lang !== language) {
            onLanguageChange(lang);
        }
    }

    useEffect(() => {
        if (
            !startupLoaded &&
            Object.keys(langTranslations || {}).length &&
            apiVersion &&
            language &&
            languageKeys) {
            setStartupLoaded(true);
        }
        // eslint-disable-next-line
    }, [apiVersion, language, languageKeys, langTranslations, requestInfo, startupLoaded]);

    return (
        <StartupContext.Provider
            value={{
                apiVersion,
                language,
                languageKeys,
                langTranslations,
                isClientLocal: requestInfo && requestInfo.requestIsLocal,
                isAuthorizedAdminIp: requestInfo && requestInfo.isAuthorizedAdminIp,
                startupLoaded,
                translate,
                changeLanguage,
            }}
        >
            {children}
        </StartupContext.Provider>
    );
};

export default StartupProvider;
