export const TOGGLE_THEME = "TOGGLE_THEME";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const Severity = { success: 'success', info: 'info', warning: 'warning', error: 'error' };

export const DefaultAlertDuration = 3000;

export const StorageKeys = {
    Sid: "SID",
    IsDarkTheme: 'is-dark-theme',
    ApiVersion: "api-version",
    Language: "language-key",
    Translations: "translations",
    ClientIp:"client-ip",
    CachedIpCount:"cached-ip-count",
};

export const StorageKeysToClearOnBrowserClose = [];