import React, { useState, useEffect } from 'react';
import styled from '@mui/material/styles/styled';
import { Box } from '@mui/system';
import './loader.css';

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.text.primary
}));

let timer = null;

const Loader = ({ show }) => {

    const [started, setStarted] = useState(false);

    const changeState = (state, canChange) => {
        if (canChange) {
            setStarted(state);
        }
    };

    useEffect(() => {
        let mounted = true;
        if (show) {
            timer = setTimeout(() => changeState(true, mounted), 600);
        } else {
            clearTimeout(timer);
            changeState(false, started && mounted);
        }
        return () => mounted = false;
    }, [show, started]);

    const reloadPage = () => window.location.reload();

    return (started ?
        <StyledBox className="loader">
            <Box
                title="reload"
                className="loaderImg loaderCircle"
                onClick={reloadPage}>
            </Box>
        </StyledBox> : null);
};

export default Loader;