import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { StartupContext } from '../../contexts/startup-context';

const HomeContainer = ({ children }) => {

    const { translate } = useContext(StartupContext);

    const TextBoxLocalized = (resourceKey) => translate(resourceKey)
        .split(".")
        .filter(x => x.length > 1)
        .map((line, i) =>
            <Box key={`${resourceKey}-${i}`} sx={{ textAlign: 'center', width: 'clamp(340px, 600px, 100%)', margin: 'auto' }}>
                <Typography variant="h5">
                    {line}
                </Typography>
            </Box>);


    return (
        <Box sx={{ textAlign: 'center', height: '620px', width: '600px', margin: 'auto' }}>
            {TextBoxLocalized("WorkDescription")}
            {children}
            {TextBoxLocalized("EquipmentDescription")}
        </Box>
    );
}

export default HomeContainer;