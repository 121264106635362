import { useState, useEffect } from 'react';

const baseTitle = "ABC TERMO"

const usePageTitle = (title) => {

    const [documentTitle, setDocumentTitle] = useState(baseTitle);

    useEffect(() => {
        const pageTitle = `${baseTitle} - ${title}`;
        document.title = pageTitle;
        setDocumentTitle(pageTitle);
    }, [title]);

    return ({ documentTitle });
};

export default usePageTitle;