import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { Fragment } from "react";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HomeIcon from '@mui/icons-material/Home';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { StartupContext } from '../../contexts/startup-context';

const SideMenuList = () => {
    const { isAuthorizedAdminIp, translate } = useContext(StartupContext);

    const appInfo = isAuthorizedAdminIp ? <List>
        <ListItem button key={"ApiInfo"} component={Link} to="/api-info" >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="API Info" />
        </ListItem>
    </List> : null;

    return (
        <Fragment>
            <Divider />
            <List>
                <ListItem button key={"Home"} component={Link} to="/" >
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary={translate("Home")} />
                </ListItem>
                <ListItem button key={"VideoFacebook"} component={Link} to="/video" >
                    <ListItemIcon>
                        <OndemandVideoIcon />
                    </ListItemIcon>
                    <ListItemText primary={translate("Video")} />
                </ListItem>
                <ListItem button key={"Contacts"} component={Link} to="/contacts" >
                    <ListItemIcon>
                        <ContactMailIcon />
                    </ListItemIcon>
                    <ListItemText primary={translate("Contacts")} />
                </ListItem>
            </List>
            <Divider />
            {appInfo}
        </Fragment>);
};

export default SideMenuList;