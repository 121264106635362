import React, { useContext } from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import FooterAnchor from "./footer-anchor";
import { Link } from 'react-router-dom';
import ContactsIcon from '@mui/icons-material/Contacts';

import { StartupContext } from '../../contexts/startup-context';

import './footer.css';

const StyledFooter = styled(Box)(({ theme }) => {
    return ({
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '0 20px',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        height: '2.8rem',
        color: theme.palette.common.white,
        backgrounColor: theme.palette.primary.dark,
        background: theme.palette.primary.dark,
        '& a': { color: `${theme.palette.common.white} !important`, textDecoration: "none", display: 'flex' },
        '& a:hover': { textDecoration: "underline" }
    });
});

const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: '0.85rem'
}));


const Footer = () => {

    const { translate } = useContext(StartupContext);

    return (
        <StyledFooter className="footer">
            <Box sx={{ display: 'flex', '& :hover': { textDecoration: 'underline', cursor: 'pointer' } }}
                onClick={() => window.open('https://rekvizitai.vz.lt/imone/abc_termo/', '_blank')}
                title={translate("Requisites")}>
                <SmallText> Copyright © {new Date().getFullYear()} </SmallText>
                <SmallText sx={{ marginLeft: '6px', display: { xs: 'none', md: 'block' } }}>ABC TERMO</SmallText>
            </Box>

            <Link to="/Contacts">
                <ContactsIcon />
                <Typography sx={{ marginLeft: '10px' }}>{translate("Contacts")}</Typography>
            </Link>

            <Box className="social" title="Facebook">
                <FooterAnchor href={"https://www.facebook.com/Abc-Termo-100985485714422"} src={"img/fb.svg"} alt={"facebook"} />
            </Box>
        </StyledFooter >
    );
};

export default Footer;