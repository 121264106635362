import React, { useMemo, Fragment, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from 'reactstrap';
import PersistentDrawerRight from './menu/persistent-drawer-right';
import Footer from "./footer/footer";
import ThemeContext from "../contexts/theme/theme-context";

import { LightThemePalette } from '../contexts/theme/light-theme-palette';
import { DarkThemePalette } from '../contexts/theme/dark-theme-palette';
import TopBanner from './banners/top-banner';

const Layout = (props) => {
  const footer = useMemo(() => <Footer />, []);

  const { darkTheme } = useContext(ThemeContext);

  const theme = createTheme(darkTheme ? DarkThemePalette : LightThemePalette);

  const ContainerMain = styled(Container)(({ theme }) => ({
    margin: '20px 20px 50px',
    boxSizing: 'content-box',

  }));

 

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <PersistentDrawerRight />
        <TopBanner />
        <ContainerMain>
          {props.children}
        </ContainerMain>
        {footer}
      </Fragment>
    </ThemeProvider >);
};

export default Layout;
