import React, { useState, useEffect, Fragment, useContext } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import usePageTitle from "../common/use-page-title";
import useApi from '../common/use-api';
import { Severity } from "../constants";
import AlertContext from "../contexts/alert/alert-context";
import Loader from "../common/loader/loader";

import withComponent from "../common/with-component";

const ApiInfo = ({ startupLoaded, translate, isAuthorizedAdminIp }) => {

    usePageTitle("ApiInfo");
    const { setAlert } = useContext(AlertContext);

    const [infoPairs, setInfoPairs] = useState([]);
    const [loading, setLoading] = useState();
    const { getData, getError } = useApi();


    useEffect(() => {
        if (!infoPairs.length && !loading && isAuthorizedAdminIp) {
            setLoading(true);
            populateData();
        }
        // eslint-disable-next-line
    }, [startupLoaded, loading, infoPairs.length, isAuthorizedAdminIp]);

    const populateData = async () => await getData("/api/apiinfo")
        .then((response) => {
            setInfoPairs(response.data);
            setAlert(translate('SuccessOperation'), Severity.success)
        }).catch((ex) => {
            setAlert(getError(ex));
        }).finally(() => {
            setLoading(false);
        });

    return (
        isAuthorizedAdminIp ? (
            loading ?
                <Loader show={loading} /> :
                <Fragment>
                    <Box>
                        <Typography variant="h4" align="center">Application INFO</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 450 }}>
                                <TableHead>
                                    <TableRow >
                                        <TableCell align="center">
                                            <Typography variant="h6">Property</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="h6">Value</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {infoPairs.map(x =>
                                        <TableRow
                                            key={x.key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {x.key}
                                            </TableCell>
                                            <TableCell align="left">{x.value}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Fragment>) : null
    );
};

export default withComponent(ApiInfo);