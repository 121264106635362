import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { StartupContext } from '../../contexts/startup-context';
import ThemeContext from '../../contexts/theme/theme-context';

const BrandTitle = () => {

    const { translate } = useContext(StartupContext);
    const { colors } = useContext(ThemeContext);


    return (
        <Typography noWrap sx={{ flexGrow: 1 }}>
            <Typography component={Link} to="/" variant="h5" sx={{
                fontWeight: 'bold',
                marginLeft: '1.5rem',
                color: `${colors.gold} !important`,
                textDecoration: 'none',
                width: '300px'
            }}>ABC TERMO - {translate("ConstructionFirm")}</Typography>
        </Typography>);
};

export default React.memo(BrandTitle);