import { useState, useEffect } from "react";
import useApi from "../use-api";
import { StorageKeys } from "../../constants";
import useTranslationHelpers from "./use-translation-helpers";
import useStoredTranslations from "./use-stored-translations";

const useResourceInit = () => {

    const foundApiVersion = localStorage.getItem(StorageKeys.ApiVersion);
    const storedLanguage = localStorage.getItem(StorageKeys.Language);
    const { getData, getError } = useApi();
    const { createLangTranslations } = useTranslationHelpers();
    const { getStoredTranslations } = useStoredTranslations();


    const [apiVersion, setApiVersion] = useState(foundApiVersion);
    const [language, setLanguage] = useState(storedLanguage);
    const [languageKeys, setLanguageKeys] = useState([]);
    const [langTranslations, setLangTranslations] = useState();
    const [versionRequested, setVersionRequested] = useState(false);
    const [requestInfo, setRequestInfo] = useState(null);


    const getResources = () => getData("/api/resource")
        .then((response) => {
            if (response.data) {
                localStorage.setItem(StorageKeys.Translations, JSON.stringify(response.data));
                //console.log(`use-translations.getResources -> versionRequested:${versionRequested}, hasData:${!!response.data}, createLangTranslations...`);
                onTranslationsCreated(createLangTranslations(language, response.data));
            }
            return response.data;
        }).catch((ex) => {
            console.error(getError(ex));
            return false;
        });

    const getRequestInfo = async () => await getData("/api/requestinfo")
        .then((response) => {
            const data=response.data;

            localStorage.setItem(StorageKeys.ApiVersion, data.apiVersion);
            localStorage.setItem(StorageKeys.ClientIp, data.clientIp);
            localStorage.setItem(StorageKeys.CachedIpCount, data.cachedIpCount);
            setRequestInfo(data);
            setVersionRequested(true);

            if (apiVersion !== data.apiVersion) {
                setApiVersion(data.apiVersion);
            }

            if (data.apiVersion !== foundApiVersion) {
                return getResources();
            }

            const {
                culture,
                storedLanguageKeys,
                storedLangTranslations,
            } = getStoredTranslations();

            if (!storedLangTranslations) {
                return getResources();
            }

            setLanguage(culture);
            setLanguageKeys(storedLanguageKeys);
            setLangTranslations(storedLangTranslations);
            return Promise.resolve(false);

        }).catch((ex) => {
            console.error(getError(ex));
            return Promise.resolve(false);
        });

    useEffect(() => {
        if (!versionRequested) {
            getRequestInfo();
        }
        // eslint-disable-next-line
    }, [versionRequested, language, langTranslations]);


    const onTranslationsCreated = ({ culture, languageKeys, parsedTranslations }) => {
        //console.log(`use-translations.onTranslationsCreated(success:${languageKeys}, parsedTranslations: ${Object.keys(parsedTranslations || {}).length},`);

        localStorage.setItem(StorageKeys.Language, culture);
        setLanguage(culture);
        setLanguageKeys(languageKeys);
        setLangTranslations(parsedTranslations);
    };

    const onLanguageChange = (lang) => {
        console.log(`use-translations -> onLanguageChange...`);
        localStorage.setItem(StorageKeys.Language, lang);
        const translations = localStorage.getItem(StorageKeys.Translations);
        if (!translations) {
            setLanguage(lang);
            setVersionRequested(false);
        } else {
            onTranslationsCreated(createLangTranslations(lang, JSON.parse(translations)));
        }
    }

    const translate = (key) => langTranslations && langTranslations.hasOwnProperty(key) ? langTranslations[key] : key;

    return {
        apiVersion,
        language,
        languageKeys,
        langTranslations,
        requestInfo,
        translate,
        onLanguageChange
        // eslint-disable-next-line
    };
};

export default useResourceInit;