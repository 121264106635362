import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import Brand from './brand';
import BrandTitle from "./brand-title";
import MenuButton from "./menu-button";
import LanguagePicker from './language-picker';

import "./menu.css";


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: `${theme.palette.primary.dark} !important`,
    backgroundImage: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));


const HeaderMenu = ({ open, handleDrawerOpen }) => {
    return (
        <AppBar position="fixed" open={open}>
            <Toolbar>
                <Brand />
                <BrandTitle />
                <LanguagePicker />
                <MenuButton handleDrawerOpen={handleDrawerOpen} open={open} />
            </Toolbar>
        </AppBar>
    );
};

export default HeaderMenu;