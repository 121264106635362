import axios from "axios";
import { StorageKeys } from '../constants';


const useApi = () => {

    const CancelToken = axios.CancelToken;
    const cancelSource = CancelToken.source();
    const isCanceled = axios.isCancel;

    const api = axios.create({
        cancelToken: cancelSource.token,
        headers: {
            "Content-Type": "application/json",
            "SID": localStorage.getItem(StorageKeys.Sid),
            "X-Lang": localStorage.getItem(StorageKeys.Language)
        },
    });

    const getData = resource => {
        //console.log(`API.getData: ${resource}`.toUpperCase());
        return api.get(resource);
    };

    const postData = (resource, payload) => {
        //console.log(`API.postData: ${resource}`.toUpperCase());
        return api.post(resource, payload);
    };

    const getErrorMessageFromData = (error, errorData) => Array.isArray(errorData) ? errorData.join(". ") : (error.message || errorData);


    const getErrorMessage = (error) => {
        const errorData = error.response && error.response.data;

        if (!errorData) {
            return error.response ? error.response.statusText : error.message;
        }

        if (errorData.title) {
            return errorData.title;
        }

        return getErrorMessageFromData(error, errorData);
    };

    return {
        api,
        cancelSource,
        isCanceled,
        getData,
        postData,
        getError: getErrorMessage,
    };
};

export default useApi;
