import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import usePageTitle from '../../common/use-page-title';
import withComponent from '../../common/with-component';
import './home.css'
import MainImage from './main-image';
import Avatars from './avatars';
import BigImage from './big-image';
import withHome from './withHome';
import HomeContainer from './home-container';


const fadeTimeout = 5000;

const Home = ({ translate }) => {

  usePageTitle(translate("Home"));

  const [clickInfo, setClickInfo] = useState({ open: false, anchor: null, url: '' });
  const [index, setIndex] = useState(0); //availabale banner count: 24
  const [show, setShow] = useState(true);

  useEffect(() => {
    let timeout;
    if (!clickInfo.open) {
      if (show) {
        timeout = setTimeout(() => setShow(false), fadeTimeout);
      } else {
        setIndex(index === 23 ? 0 : (index + 1)); setShow(true);
      }
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [index, show, clickInfo]);

  const { id, appendix, handleClick } = withHome(clickInfo, setClickInfo);

  return (
    <HomeContainer>
      <Box sx={{ position: 'relative', height: '520px', width: '520px', margin: 'auto auto 22px' }}>
        <MainImage show={show} imgNumber={appendix(index)} />
        <Box sx={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0' }}>
          <Avatars show={show} index={index} handleClick={handleClick} fadeTimeout={fadeTimeout} />
          <BigImage translate={translate} id={id} open={clickInfo.open} clickInfo={clickInfo} setClickInfo={setClickInfo} />
        </Box>
      </Box>
    </HomeContainer>
  );
};

export default withComponent(Home);
