import React from "react";
import { Snackbar, Slide, IconButton } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { DefaultAlertDuration, Severity } from "../../constants";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TransitionRight = props => <Slide {...props} direction="right" />;

const AlertItem = ({ alert, open, handleClose }) => {

    return (alert ?
        <Snackbar
            open={open}
            disableWindowBlurListener={true}
            onClose={handleClose}
            TransitionComponent={TransitionRight}
            autoHideDuration={(alert.duration) || DefaultAlertDuration}>
            <Alert severity={alert.type || Severity.error} sx={{ wordBreak: 'break-word' }}>
                {alert.msg}
                <IconButton
                    sx={{ p: 0.5 }}
                    onClick={handleClose}
                ><CloseIcon />
                </IconButton>
            </Alert>
        </Snackbar>
        : null);
};

export default AlertItem;
