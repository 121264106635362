import React, { useReducer } from "react";
import ThemeContext from "./theme-context";
import ThemeReducer from "./theme-reducer";

import { TOGGLE_THEME, StorageKeys } from "../../constants";
import { ThemeColors } from "./theme-colors";

const ThemeState = ({ children }) => {

    const isDarkTheme = localStorage.getItem(StorageKeys.IsDarkTheme) !== "false";

    const initialState = { darkTheme: isDarkTheme, colors: ThemeColors(isDarkTheme) };

    document.getElementById("root").className = !isDarkTheme ? 'light' : 'dark';

    const [state, dispatch] = useReducer(ThemeReducer, initialState);

    const toggleTheme = () => dispatch({ type: TOGGLE_THEME });

    return (
        <ThemeContext.Provider
            value={{
                darkTheme: state.darkTheme,
                colors: ThemeColors(state.darkTheme),
                toggleTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeState;
