import React, { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Fade from '@mui/material/Fade';

import ThemeContext from '../../contexts/theme/theme-context';

const Avatars = ({ show, index, handleClick }) => {
    const { darkTheme, colors } = useContext(ThemeContext);
    const appendix = (from) => String(from + 1).padStart(2, '0');

    return ([...Array(8).keys()].map((i) => {
        const rowNumber = appendix(Math.floor(index / 8) * 8 + i);
        return (
            <Fade in={show} timeout={500} key={`fade${rowNumber}`} >
                <Avatar key={`avatar${rowNumber}`}
                    sx={{
                        height: '70px',
                        width: '70px',
                        border: `2px ${(Math.floor(index / 8) * 8 + i) === index ? 'dotted' : 'ridge'} ${darkTheme ? colors.gold : 'Silver'}`
                    }}
                    className={`avatar avatar0${i + 1}`}
                    alt={`ABC${rowNumber}`}
                    src={`img/banner-group-01/banner-${rowNumber}-200x150.gif`}
                    onClick={handleClick} />
            </Fade>
        )
    }));
};

export default Avatars;
