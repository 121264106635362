import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import VideoFacebook from './components/VideoFacebook';
import Home from './components/home';
import ApiInfo from './components/ApiInfo';
import Contacts from './components/Contacts';
import ThemeState from './contexts/theme/theme-state';
import StartupProvider from './contexts/startup-context';
import AlertProvider from './contexts/alert/alert-provider';
import AlertComponent from './common/alert/alert-component';
import { StorageKeysToClearOnBrowserClose } from './constants';

import './custom.css';

const App = () => {

  window.addEventListener("beforeunload", (_) => {
    StorageKeysToClearOnBrowserClose.forEach(key => window.localStorage.removeItem(key));
  });

  return (
    <AlertProvider>
      <StartupProvider>
        <ThemeState>
          <Layout>
            <AlertComponent />
            <Switch>
              <Route exact path='/video' component={VideoFacebook} />
              <Route exact path='/contacts' component={Contacts} />
              <Route exact path='/api-info' component={ApiInfo} />
              <Route component={Home} />
            </Switch>
          </Layout>
        </ThemeState>
      </StartupProvider>
    </AlertProvider>
  );
};

export default App;
